import React from 'react';
import logo from './logo.svg';
import './App.css';
import { TitlesIndex, pathsPublic } from './routes/helpers';
import { HeaderNavbar } from './components/Header/HeaderNavbar';

export const ProjectTitle = 'Anevo'
export const navigation = [
  { index: TitlesIndex.Home, name: 'Home', href: pathsPublic.home, current: false },
  { index: TitlesIndex.Instruments, name: 'Instruments', href: pathsPublic.instruments, current: false },
  { index: TitlesIndex.Blog, name: 'Blog', href: pathsPublic.blog, current: false },
  { index: TitlesIndex.API, name: 'API docs', href: pathsPublic.api, current: false },
]
function App() {
  return (
    <div className="App">
      <HeaderNavbar/>
    </div>
  );
}

export default App;
